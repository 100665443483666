import {
  Alert,
  AlertContent,
  AlertTitle,
  AnchorRouter,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/Components'
import { renderCurrencyMarkRule } from '@/Components/Content/renderMarkRules'
import {
  renderHeadingNodeRule,
  renderLinkNodeRule,
  renderOLNodeRule
} from '@/Components/Content/renderNodeRules'
import {
  AccordionRecord,
  AlertRecord,
  InlineVideoRecord,
  LineChartRecord,
  PartnerRecord,
  ScreenshotRecord,
  SummaryRecord,
  TableRecord
} from '@/Generated/dato-cms-graphql'
import { cn } from '@/Lib'
import { FC, useMemo } from 'react'
import { StructuredText } from 'react-datocms'
import {
  Cell,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from './Accordion'

const AccordionRecordBlock: FC<{ record: AccordionRecord }> = ({ record }) => (
  <Accordion type='multiple'>
    {record.items.map((item) => {
      return (
        <AccordionItem key={item.id} value={item.id}>
          <AccordionTrigger>{item.header}</AccordionTrigger>
          <AccordionContent className='py-0'>
            <StructuredText
              data={item.content as never}
              customNodeRules={[
                renderLinkNodeRule,
                renderOLNodeRule,
                renderHeadingNodeRule
              ]}
              customMarkRules={[renderCurrencyMarkRule]}
            />
          </AccordionContent>
        </AccordionItem>
      )
    })}
  </Accordion>
)

AccordionRecordBlock.displayName = 'AccordionRecordBlock'

const AlertRecordBlock: FC<{ record: AlertRecord }> = ({ record }) => (
  <Alert
    className={cn(
      'my-5',
      record.colour == 'slate-100' && 'bg-slate-100 marker:text-slate-400',
      record.colour == 'green-100' && 'bg-green-100 marker:text-green-400',
      record.colour == 'orange-100' && 'bg-orange-100 marker:text-orange-400'
    )}
  >
    {record.icon && <Icon>{record.icon}</Icon>}
    <AlertContent className='flex prose-h3:my-0 prose-h3:text-base prose-p:mb-0 prose-p:mt-0'>
      {record.title && <AlertTitle>{record.title}</AlertTitle>}
      {record.body &&
        record.body.value.document.children[0].children[0].value != '' && (
          <StructuredText
            data={record.body as never}
            customNodeRules={[
              renderLinkNodeRule,
              renderOLNodeRule,
              renderHeadingNodeRule
            ]}
            customMarkRules={[renderCurrencyMarkRule]}
          />
        )}
    </AlertContent>
  </Alert>
)

AlertRecordBlock.displayName = 'AlertRecordBlock'

const InlineVideoRecordBlock: FC<{ record: InlineVideoRecord }> = ({
  record
}) => {
  switch (record.video?.provider) {
    case 'youtube':
      return (
        <iframe
          className='w-full'
          style={{ aspectRatio: '16 / 9' }}
          src={`https://www.youtube-nocookie.com/embed/${record.video?.providerUid}`}
          title={record.video?.title}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      )
    default:
      return null
  }
}

InlineVideoRecordBlock.displayName = 'InlineVideoRecordBlock'

const ScreenshotRecordBlock: FC<{ record: ScreenshotRecord }> = ({
  record
}) => (
  <img
    src={record.image?.url}
    role='img'
    alt={record.image?.alt ?? 'Fynbos image'}
    className='my-5'
  />
)

ScreenshotRecordBlock.displayName = 'ScreenshotRecordBlock'

const SummaryRecordBlock: FC<{ record: SummaryRecord }> = ({ record }) => (
  <Alert className='my-5 flex bg-blue-100 p-8'>
    <AlertContent className='marker:text-blue-400 prose-h3:my-0 prose-h3:text-base prose-p:my-0'>
      {record.title && <AlertTitle>{record.title}</AlertTitle>}
      {record.body &&
        record.body.value.document.children[0].children[0].value != '' && (
          <StructuredText
            data={record.body as never}
            customNodeRules={[
              renderLinkNodeRule,
              renderOLNodeRule,
              renderHeadingNodeRule
            ]}
            customMarkRules={[renderCurrencyMarkRule]}
          />
        )}
    </AlertContent>
  </Alert>
)

SummaryRecordBlock.displayName = 'SummaryRecordBlock'

const PartnerRecordBlock: FC<{ record: PartnerRecord }> = ({ record }) => (
  <div className='my-5 flex w-full flex-col rounded-lg bg-slate-100 p-8'>
    <div className='flex flex-col-reverse justify-between sm:flex-row'>
      <div className='flex flex-col items-start justify-center gap-2 prose-h2:my-0'>
        <h2>{record.title}</h2>
        {record.url && (
          <AnchorRouter href={record.url}>{record.url}</AnchorRouter>
        )}
      </div>
      <img
        src={record.logo?.url}
        role='img'
        alt={record.logo?.alt || `${record.title} logo`}
        className={'my-5'}
      />
    </div>
    {record.body &&
      record.body.value.document.children[0].children[0].value != '' && (
        <StructuredText
          data={record.body as never}
          customNodeRules={[
            renderLinkNodeRule,
            renderOLNodeRule,
            renderHeadingNodeRule
          ]}
          customMarkRules={[renderCurrencyMarkRule]}
        />
      )}
  </div>
)

PartnerRecordBlock.displayName = 'PartnerRecordBlock'

const TableRecordBlock: FC<{ record: TableRecord }> = ({ record }) => (
  <Table key={record.id}>
    <TableHead>
      <TableRow>
        {record.content.columns.map((column: string) => (
          <TableHeader key={column}>{column}</TableHeader>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {record.content.data.map(
        (row: { [key: string]: string }, index: number) => (
          <TableRow key={`${record.id}-row-${index}`}>
            {record.content.columns.map((column: string) => (
              <TableCell key={row.id + column}>{row[column]}</TableCell>
            ))}
          </TableRow>
        )
      )}
    </TableBody>
  </Table>
)

TableRecordBlock.displayName = 'TableRecordBlock'

const lineColours = [
  'text-yellow-300',
  'text-green-300',
  'text-sky-300',
  'text-purple-300'
]

// TODO Make zoomable https://recharts.org/en-US/examples/HighlightAndZoomLineChart
const ChartRecordBlock: FC<{ record: LineChartRecord }> = ({ record }) => {
  return (
    <div
      key={record.id}
      className='relative z-0 my-10 flex aspect-video w-full'
    >
      {record.chartType == 'pie' && <PieChartBlock record={record} />}
      {record.chartType == 'line' && <LineChartBlock record={record} />}
    </div>
  )
}

ChartRecordBlock.displayName = 'LineChartRecordBlock'

const LineChartBlock: FC<{ record: LineChartRecord }> = ({ record }) => {
  return (
    <div
      key={record.id}
      className='relative z-0 my-10 flex aspect-video w-full'
    >
      <ResponsiveContainer key={record.id} width='100%' height='100%'>
        <LineChart
          width={500}
          height={300}
          data={record.content.data}
          margin={{
            top: 5,
            bottom: 5
          }}
        >
          <YAxis
            label={{
              style: { textAnchor: 'middle' },
              angle: -90,
              position: 'left',
              offset: 0
            }}
            className='text-xs'
          />
          <XAxis className='text-xs' dataKey={record.content.columns[0]} />
          <Tooltip
            label={record.content.columns[0]}
            labelFormatter={(value) => value}
            contentStyle={{
              borderRadius: '0.75rem',
              backgroundColor: 'white'
            }}
            cursor={false}
          />
          {record.content.columns.map((column: string, index: number) =>
            index == 0 ? null : (
              <Line
                type='natural'
                key={column}
                dataKey={column}
                className={lineColours[index % lineColours.length]}
                fill='currentColor'
                stroke='currentColor'
                dot={false}
                strokeWidth={2}
                activeDot={{ r: 4 }}
              />
            )
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

LineChartBlock.displayName = 'LineChartBlock'

const RADIAN = Math.PI / 180

const PieChartBlock: FC<{ record: LineChartRecord }> = ({ record }) => {
  const data: Record<string, string | number>[] = useMemo(() => {
    return record.content.data.map((data: { [x: string]: number }) => {
      const val = Number(data[record.content.columns[1]])
      data[record.content.columns[1]] = val
      return data
    })
  }, [record.content.columns, record.content.data])

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    percent,
    index
  }: {
    cx: number
    cy: number
    midAngle: number
    outerRadius: number
    percent: number
    index: number
  }) => {
    const label = data[index][record.content.columns[0]]

    const radius = outerRadius + 15
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        className='text-xs text-medium'
        fill='currentColor'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {`${label} - ${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  return (
    <div key={record.id} className='relative z-0 flex aspect-video w-full'>
      <ResponsiveContainer key={record.id} width='100%' height='100%'>
        <PieChart
          width={500}
          height={300}
          margin={{
            top: 5,
            bottom: 5
          }}
        >
          <Pie
            dataKey={record.content.columns[1]}
            data={data}
            cx='50%'
            cy='50%'
            outerRadius={100}
            fill='currentColor'
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                className={lineColours[index % lineColours.length]}
                fill='currentColor'
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

PieChartBlock.displayName = 'PieChartBlock'

export {
  AccordionRecordBlock,
  AlertRecordBlock,
  ChartRecordBlock,
  InlineVideoRecordBlock,
  PartnerRecordBlock,
  ScreenshotRecordBlock,
  SummaryRecordBlock,
  TableRecordBlock
}
